import React from 'react'


function Enthusiasts() {
    return (
        <div className="Body">
            <div className="container"><h2>For Gamers:</h2>
                <p>We understand that gaming is more than just a hobby-it's a lifestyle. Our custom-built gaming PCs are crafted with the latest technology to provide you with the ultimate gaming experience. From ultra-fast processors and high-performance graphics cards to advanced cooling systems and stunning aesthetics, each build is tailored to deliver exceptional performance and immersive gameplay. Our expert team is dedicated to helping you achieve peak performance, ensuring that every frame, every action, and every game is experienced at its best.</p>

                <a href="/enthusiasts/custompcs"><button>Exlpore Options</button></a>
            </div>
            <div className="container">
                <h2>Our Commitment:</h2>
                <p>We pride ourselves on our unwavering commitment to quality, innovation, and customer satisfaction. Every product we build and every service we offer is backed by our dedication to excellence. We believe in creating lasting relationships with our clients by providing exceptional value, expert advice, and personalized support.</p>
                <p>Join us at kaotik komputing, and let us help you unlock the full potential of your technology. Whether you're aiming to conquer virtual worlds or streamline your business operations, we are here to make it happen.</p>
            </div>
            <div className="container">
                <h2>Custom Gaming Computer Building Service</h2>
                <p>Are you looking to build the ultimate gaming computer tailored to your specific needs? Our custom gaming computer building service is here to help you create a high-performance, personalized gaming rig. Whether you're a casual gamer or a professional eSports player, we offer expert guidance and top-quality components to bring your dream PC to life.</p>

                <h2>Why Choose Our Service?</h2>
                <ul>
                    <li><strong>Expert Consultation:</strong> Work with our experienced technicians to select the best components for your gaming needs and budget.</li>
                    <li><strong>Quality Components:</strong> We use only the highest quality parts from reputable brands to ensure superior performance and reliability.</li>
                    <li><strong>Personalized Build:</strong> Customize every aspect of your gaming PC, from performance specifications to aesthetic details.</li>
                    <li><strong>Professional Assembly:</strong> Our skilled technicians will assemble your PC with precision, ensuring optimal performance and durability.</li>
                    <li><strong>Comprehensive Testing:</strong> Each custom build undergoes rigorous testing to guarantee stability and peak performance.</li>
                    <li><strong>Warranty and Support:</strong> Enjoy peace of mind with our comprehensive warranty and dedicated support team.</li>
                </ul>

                <h2>Our Process</h2>
                <h3>1. Initial Consultation</h3>
                <p>We'll start with a consultation to understand your gaming requirements, preferences, and budget. This helps us recommend the best components for your custom build.</p>

                <h3>2. Component Selection</h3>
                <p>Based on your needs, we'll help you select the ideal components, including CPU, GPU, RAM, storage, motherboard, power supply, and cooling solutions.</p>

                <h3>3. Custom Build</h3>
                <p>Our technicians will professionally assemble your gaming PC, ensuring each component is installed correctly and securely.</p>

                <h3>4. Testing and Optimization</h3>
                <p>Your custom gaming computer will undergo thorough testing to ensure stability, performance, and cooling efficiency. We'll also optimize the system for your specific gaming needs.</p>

                <h3>5. Delivery and Setup</h3>
                <p>Once your PC is ready, we'll deliver it to your location and assist with the initial setup remotely if needed, ensuring you're ready to start gaming immediately.</p>

                <div className="cta">
                    <p>Ready to build your dream gaming PC? Contact us today to get started!</p>
                    <a href="/contact"><button>Get Started</button></a>
                </div>
            </div>
        </div>
    )
}

export default Enthusiasts;