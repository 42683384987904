// Contact.tsx
import React from 'react';

function Contact() {
    return (
        <div className="container">
            <h1>Contact Us</h1>
            <div className="contact-info">
                <h2>Email Addresses</h2>
                <ul>
                    <li>
                        <strong>Sales:</strong> <a href="mailto:sales@kaotikkomputing.com">sales@kaotikkomputing.com</a>
                        <p>Contact our sales team for inquiries about our high-end gaming PCs, pricing, bulk orders, or any sales-related questions. We're here to help you find the perfect computing solution to meet your needs.</p>
                    </li>
                    <li>
                        <strong>Support:</strong> <a href="mailto:support@kaotikkomputing.com">support@kaotikkomputing.com</a>
                        <p>Reach out to our support team for technical assistance, troubleshooting, or any issues you may encounter with our products or services. Our dedicated support staff is ready to provide you with prompt and effective solutions.</p>
                    </li>
                    <li>
                        <strong>General Information:</strong> <a href="mailto:info@kaotikkomputing.com">info@kaotikkomputing.com</a>
                        <p>For general inquiries, company information, or to learn more about Kaotik Komputing LLC, please contact us at this email. We're happy to provide you with any information you need and address your questions.</p>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Contact;
