import React from 'react';
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Disposal from './pages/disposal';
import CustomPCs from './pages/custompcs'
import Services from './pages/services';
import Purchase from './pages/purchase';
import Enthusiasts from './pages/enthusiasts';

function App() {
    return (
        <div className="App">
            <Router>
                <div className="App">
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/enthusiasts" element={<Enthusiasts />} />
                        <Route path="/enthusiasts/custompcs" element={<CustomPCs/>} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/services/purchase" element={<Purchase />} />
                        <Route path="/services/disposal" element={<Disposal />} />

                        <Route path="/about" element={<About />} />

                        <Route path="/contact" element={<Contact/>} />
                    </Routes>
                    <Footer />
                </div>
            </Router>
        </div>
    );
}

export default App;
