// Header.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg';
import logo2 from '../assets/logo-transparent.svg'
import MenuItem from './MenuItem'; 
function Header() {
    return (
        <header>
            
                <div className="fill">
                    <Link to="/">
                        <img src={logo2} className="App-logo" alt="Logo" />
                    </Link>
                    
                </div>
                <nav>
                    <ul className="menuItem fill">
                        {/*<li> <img src={logo} className="App-logo" alt="logo" /></li>*/}
                        <li>
                            <MenuItem Label="Home" Target="/"/>
                        </li>
                        <li>
                            <MenuItem Label="Professional Services" Target="/services"/>
                        </li>
                        <li>
                            <MenuItem Label="Custom Gaming" Target="/enthusiasts" />
                        </li>
                        <li>
                            <MenuItem Label="About" Target="/about" /> 
                        </li>
                        <li>
                            <MenuItem Label="Contact" Target="/contact" />
                        </li>
                    </ul>
                </nav>
        </header>
    );
}

export default Header;