// About.tsx
import React from 'react';

function About() {
    return (
        <div className="container">
            
            <div className="profile">
                <div>
                    <img src="gerald-noonan.jpg" alt="Gerald A Noonan" className="portrait"/>
                </div>
                <div className="profile-info">
                    <h2>Gerald A Noonan</h2>
                    <p><strong>Founder & CEO, kaotik komputing llc</strong></p>
                    <p>Gerald A Noonan is the visionary founder and CEO of kaotik komputing llc., a leading provider of high-end gaming PCs and professional IT solutions. With a deep passion for technology and innovation, Gerald has dedicated his career to creating exceptional computing experiences for both privately and professionaly.</p>
                    <h3>Professional Background</h3>
                    <ul>
                        <li>Over 18 years of experience in the tech industry</li>
                        <li>Expert in IT infrastructure, and software development, and hardware acquisitions</li>
                        <li>Former Senior Full Stack Engineer</li>
                    </ul>
                    <h3>Achievements</h3>
                    <ul>
                        <li>Successfully launched Kaotik Komputing LLC in 2024</li>
                        <li>Developed and delivered thousands for hardware and software solutions over a decades long career</li>
                    </ul>
                    <h3>Education</h3>
                    <ul>
                        <li>Bachelor's Degree in Applied Mathematics and Statistics from SUNY Stony Brook</li>
                    </ul>
                    <h3>Personal Interests</h3>
                    <p>In addition to his professional pursuits, Gerald is an avid gamer and enjoys staying updated with the latest advancements in technology. He is also passionate about mentoring young tech enthusiasts and contributing to the tech community through various initiatives and events.</p>
                </div>
            </div>
        </div>
    );
}

export default About;