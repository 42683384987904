// Home.tsx
import React from 'react';

function Home() {
    return (
        <div className="Body">
            <div className="container">
                <h2>Let us manage your kaos</h2>
             

                <p>Welcome to <strong>kaotik komputing </strong>, where innovation meets performance. Our mission is to deliver unparalleled computing experiences through our high-end gaming PCs and comprehensive professional hardware and software services for businesses.</p>

                <p>At kaotik komputing, we are driven by a passion for technology and a commitment to excellence. Whether you're a gaming enthusiast seeking the ultimate gaming rig or a business in need of reliable, cutting-edge IT solutions, we are here to meet your needs with precision and dedication.</p>

                
            </div>
        </div>
    );
}

export default Home;