// disposal.tsx
import React from 'react';

function Disposal() {
    return (
        <div className="container">
            <h1>Merits of Proper Computer Equipment Disposal</h1>
            <p>Proper disposal of computer equipment is crucial for both environmental sustainability and data security. Here are the key benefits:</p>

            <h2>Environmental Benefits</h2>
            <ul>
                <li><strong>Reduces E-Waste:</strong> Responsible disposal helps minimize the harmful impact of electronic waste on our planet.</li>
                <li><strong>Prevents Pollution:</strong> Proper handling prevents toxic substances like lead and mercury from leaching into soil and water, protecting human health and wildlife.</li>
                <li><strong>Supports Recycling:</strong> Many components of old computers can be repurposed or recycled, reducing the need for new raw materials and conserving natural resources.</li>
            </ul>

            <h2>Data Security</h2>
            <ul>
                <li><strong>Protects Sensitive Information:</strong> Responsible disposal practices often include data destruction, ensuring that personal and organizational data does not fall into the wrong hands.</li>
                <li><strong>Prevents Identity Theft:</strong> By securely destroying data, we protect against potential breaches and identity theft.</li>
            </ul>

            <h2>Economic Benefits</h2>
            <ul>
                <li><strong>Promotes Circular Economy:</strong> Recycling and repurposing components contribute to a circular economy, reducing the demand for new manufacturing.</li>
                <li><strong>Reduces Carbon Footprint:</strong> Using recycled materials lowers the carbon footprint associated with producing new devices.</li>
            </ul>

            <p>By adhering to proper computer equipment disposal protocols, we contribute to a safer, cleaner environment and promote sustainable practices that benefit both current and future generations.</p>
        </div>
    );
}

export default Disposal;