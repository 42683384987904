import React from 'react'


function Services() {
    return (
        <div>
            <div className="container">
                <h2>For Businesses:</h2>
                <p>In today's fast-paced digital world, businesses need robust and reliable IT solutions to stay competitive. kaotik komputing offers a wide range of professional services, including hardware procurement, software development, network setup, and IT support. Our team of experienced professionals works closely with you to understand your unique requirements and provide customized solutions that enhance productivity, security, and efficiency. Whether you need a complete IT infrastructure overhaul or ongoing support and maintenance, we are your trusted partner in technology.</p>

                <a href="/contact"><button>Contact Us Today!</button></a>
            </div>


            <div className="build-gallery">
                <div className="build-card">
                    <div className="build-card-content">
                        <h3>Workstations</h3>
                        <p>Our custom workstations are built using the latest technology and highest quality components, providing you with powerful, efficient, and future-proof solutions. We work closely with you to understand your needs and preferences, ensuring that each workstation we build enhances your business operations and supports your goals. With our commitment to exceptional craftsmanship and customer service, Kaotik Komputing LLC is your trusted partner for all your professional computing needs.</p>
                    </div>
                </div>
                <div className="build-card">
                    <div className="build-card-content">
                        <h3>Servers</h3>
                        <p>Our servers are constructed using the latest technologies and high-quality components, guaranteeing optimal performance and longevity. We collaborate closely with you to understand your business needs and technical specifications, ensuring that each server is customized to support your objectives and enhance your IT infrastructure. With our commitment to quality and customer satisfaction, Kaotik Komputing LLC is your trusted partner for all your custom server needs.</p>
                    </div>

                </div>
                <div className="build-card">
                    <div className="build-card-content">
                        <h3>Infrastructure</h3>
                        <p>Our custom infrastructure solutions utilize cutting-edge technology and high-quality components to ensure reliability and scalability. We work closely with you to understand your business requirements and technical challenges, crafting networking solutions that support your growth and enhance your IT infrastructure. From initial design to implementation and ongoing support, Kaotik Komputing LLC is dedicated to providing exceptional service and customized networking solutions that empower your business.</p>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Services;