import React from 'react'


function MenuItem(props : any) {
    return (
        <a href={props.Target}><button className="menuItem">{props.Label}</button></a >

    );
}

export default MenuItem