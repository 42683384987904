// disposal.tsx
import React from 'react';


function CustomPCs() {
    return (
        <div>
            <div className="container">
                <h2>Custom Gaming Builds Showcase</h2>
                <p>Welcome to our custom gaming builds showcase! Here are some of the high-performance, personalized gaming rigs we've crafted for our clients. Each build is meticulously designed to meet the specific needs and preferences of the gamer.</p>

                <div className="build-gallery">
                    <div className="build-card">
                        <img src="/Builds/TheBeast/TheBeast.jpg" alt="The Beast"></img>
                        <div className="build-card-content">
                            <h3>High-End Gaming Beast</h3>
                            <p><strong>Specifications:</strong>
                                <ul>
                                    <li>Intel(R) Core(TM) i9</li>
                                    <li>ASUS ROG Strix Z790-E Gaming Wifi</li>
                                    <li>NVIDIA Geforce RTX 4060</li>
                                    <li>64GB RAM DDR5 Corsair Vengeance</li>
                                    <li>2x 2TB NVMe drives</li>
                                    <li>4TB HDD</li>
                                    <li>1000w Corsair Modular Power Supply</li>

                                </ul>

                            </p>
                            <p><strong>Features:</strong></p>
                            <p>
                                <ul>
                                    <li>RGB Lighting</li>
                                    <li>Liquid Cooled</li>
                                    <li>Full Tower</li>
                                </ul>
                            </p>
                        </div>
                    </div>

                    <div className="build-card">
                        <img src="/Builds/Frost/Frost.jpg" alt="Frost" />
                        <div className="build-card-content">
                            <h3>Mid-Range Performance</h3>
                            <p><strong>Specifications:</strong></p>
                            <p>
                                <ul>
                                    <li>Intel(R) Core(TM) i7</li>
                                    <li>MSI MPG Z790 EDGE WIFI</li>
                                    <li>NVIDIA Geforce RTX 4060</li>
                                    <li>32GB RAM DDR5 Corsair Vengeance</li>
                                    <li>2TB NVMe drive</li>
                                    <li>850w Corsair Modular Power Supply</li>
                                </ul>
                            </p>
                            <p><strong>Features:</strong></p>
                            <p>
                                <ul>
                                    <li>Liquid Cooled</li>
                                    <li>Mid Tower</li>
                                    <li>High Vis design</li>
                                </ul>                  
                            </p>
                        </div>

                    </div>

                    {/*<div className="build-card">*/}
                    {/*    <img src="build3.jpg" alt="Custom Gaming Build 3" />*/}
                    {/*    <div className="build-card-content">*/}
                    {/*        <h3>Budget-Friendly Powerhouse</h3>*/}
                    {/*        <p><strong>Specifications:</strong> Intel Core i5, AMD RX 570, 8GB RAM, 256GB SSD</p>*/}
                    {/*        <p><strong>Features:</strong> Quiet operation, blue LED lighting, durable case</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>
            </div>
        </div>
    )
}

export default CustomPCs